<template>
  <div class="modal-inner-container">
    <div class="w-full">
      <div class="text-n-4xl font-bold">
        {{$t('security.twofa.your_secure_key')}}
      </div>

      <div class="text-n-md font-medium mt-n-5xl">
        {{$t('security.twofa.copy_key')}}
      </div>
    
      <div class="flex items-center mt-n-2xl">
        <div class="secret-twofa">{{ secret }}</div>
        <input ref="secretRef" v-model="secret" data-lpignore="true" data-testid="input-secret" class="w-n-px opacity-0" readonly type="text" />
      </div>
    </div>

    <div class="w-full mt-n-5xl">
      <ButtonV2
        @onClick="continueAction"
        :label="$t('continue')"
        testId="btn-contiue"
        wide
      />
      <ButtonV2
        @onClick="copy"
        :label="$t('copy_to_clipboard')"
        testId="btn-copy"
        version="secondary"
        class="mt-n-2xl"
        wide
      />
    </div>
  </div>
</template>

<script>
  import ButtonV2 from '@/stories/misc/ButtonV2';

  export default {
    name: 'YourSecureKey',
    components: {
      ButtonV2,
    },

    async mounted() {
      await this.$store.dispatch('events/track', {
        event: 'TWO_FACTOR_ENABLE_MANUAL_KEY_VIEWED',
      });
    },

    props: {
      secret: {
        type: String,
        required: true,
      },
      recovery: {
        type: String,
        required: true,
      },
    },
    methods: {
      copy() {
        const copyText = this.$refs.secretRef;

        copyText.select();
        copyText.setSelectionRange(0, 99999);

        document.execCommand('copy');

        this.showSuccess(this.$t('success.key_copy'));
      },
      continueAction() {
        const { recovery } = this.$props;

        this.showModal('TwoFactorAuthenticationBackupCode', { recovery });
      },
    }
  };
</script>

<style scoped>
.secret-twofa {
  @apply bg-background-page rounded-16 text-n-2xl font-semibold px-n-3xl py-n-2xl;
  overflow-wrap: anywhere;
}
</style>
